<template>
  <div class="app">
    <!-- 无数据提示 -->
    <div class="noDATA">
      <i class="iconfont icon-wushuju"></i>
      <div class="dataNodes">暂无更多数据！</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url('//at.alicdn.com/t/c/font_4244400_o5fahaqkhzc.css');
.app {
  width: 100%;
  .noDATA {
    width: 80%;
    height: 50rem;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    i {
      font-size: 150px;
      color: #7b7c7e;
    }
    .dataNodes {
      color: #7b7c7e;
      margin-top: 1.5625rem;
      font-size: 1rem;
    }
  }
}
</style>
