<template>
  <div class="app">
    <div class="app-content">
      <div class="leftNav">
        <div
          :class="
            $route.query.columnTheme == 'DEFAULT'
              ? 'lefthead'
              : 'leftheadchange'
          "
        >
          {{ $store.state.dynamicParentName.columnsName }}
        </div>
        <div class="parents">
          <div
            class="mutios"
            :class="{
              classStyle:
                $store.state.statusTypes == 0
                  ? $store.state.secondLevel.id === obj.id &&
                    $route.query.columnTheme == 'DEFAULT'
                  : index === countent,
              classStylechange:
                $store.state.statusTypes == 0
                  ? $store.state.secondLevel.id === obj.id &&
                    $route.query.columnTheme == 'RED'
                  : index === countent,
            }"
            @click="navRightFn(index, newsList, obj)"
            v-for="(obj, index) in newsList"
            :key="obj.id"
          >
            <span> {{ obj.columnsName }}</span>
            <i class="iconfont icon-xiayibu"></i>
          </div>
        </div>
      </div>
      <div class="rightNav">
        <div class="head_title">
          <img
            width="1.25rem"
            height="1.25rem"
            src="../../../assets//allImage//presscenter/icon_home@2x.png"
            alt=""
          />
          <div>当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item style="color: #999999"
              ><router-link :to="{ path: '/' }"
                >首页</router-link
              ></el-breadcrumb-item
            >
            <el-breadcrumb-item>{{
              $store.state.dynamicParentName.columnsName
            }}</el-breadcrumb-item>
            <el-breadcrumb-item v-show="$store.state.secondLevel.columnsName">{{
              $store.state.statusTypes == 0
                ? $store.state.secondLevel.columnsName
                : currentNickname
            }}</el-breadcrumb-item>
          </el-breadcrumb>
          <!-- <div v-for="obj in currentPosition" :key="obj.id">
            {{ obj.title }}
          </div> -->
        </div>
        <div
          class="headContent"
          v-loading="loading"
          element-loading-text="拼命加载中"
        >
          <div
            class="statrLisr"
            v-for="(item, index) in $route.query.isRegular == 2
              ? depListS
              : ArrrayList"
            :key="index"
            @click="getDetalis(item, index)"
          >
            <img
              style="
                width: 1.25rem;
                position: absolute;
                top: 0.5625rem;
                left: -0.0625rem;
              "
              :src="$route.query.columnTheme == 'DEFAULT' ? defaultImg : redImg"
              alt=""
            />
            <div class="leftBox">
              <!-- 热 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'HOT'">
                <span class="firestormRe">热</span>
              </span>

              <!-- 新 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'FLATNEW'">
                <span class="firestorm">新</span>
              </span>
              <!-- 热新 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'HOTNEW'">
                <span class="firestormRe">热</span>
                <span class="firestorm">新</span>
              </span>
              <div style="color: #f26363" v-if="item.isTop == 1">[置顶]</div>
              <span class="depTitle">
                {{ $route.query.isRegular == 2 ? item.deptName : item.title }}
              </span>
            </div>

            <div>{{ item.updateTime }}</div>
          </div>
          <noDataPrompt v-if="listTypes" />
        </div>
        <!-- 分页 -->
        <div class="pagination_block" v-if="total > 0">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNum"
            :page-sizes="[10, 20, 30]"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noDataPrompt from '../../noDataPrompt/index.vue';
// import { companyArticleDetails, companyDeails } from '@/api/index';
import {
  slideshowList,
  newsListpAGE,
  companyDepartmentSetting,
} from '@/api/index';
export default {
  components: {
    noDataPrompt,
  },
  data() {
    return {
      defaultImg: require('../../../assets//allImage//presscenter//wenzitubiao@2x.png'),
      redImg: require('../../../assets/allImage/featureColumn/tubiao@2x.png'),
      ArrrayList: [],
      listTypes: false,
      depListS: [],
      loading: false,
      total: 10,
      Details: [],
      detailsList: [],
      countent: 0,
      nativeFn: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      paramID: '',
      argument: {
        id: '',
        columnId: '',
      },
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      currentPage4: 4,
      ArrrayList: [], // 文章列表
      currentPosition: [
        {
          id: 0,
          title: '首页 >',
        },
        {
          id: 1,
          title: '新闻中心 >',
        },
        {
          id: 2,
          title: '行业新闻 ',
        },
      ],
      contents: '',
      navlist: [
        {
          id: 0,
          title: '行业新闻',
        },
        {
          id: 1,
          title: '媒体报道',
        },
        {
          id: 2,
          title: '公司新闻',
        },
      ],
      Alltotal: 0,
    };
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newId, oldId) {
        this.id = newId;
        this.getList(newId); // 文章列表
      },
    },
  },
  created() {
    this.depList();
  },
  methods: {
    async getList(id) {
      this.ArrrayList = [];
      this.loading = true;
      this.queryParams.columnId = id;
      const newsList = await newsListpAGE(this.queryParams);
      if (newsList.data.code) {
        if (
          newsList.data.data.records &&
          newsList.data.data.records.length > 0
        ) {
          this.total = newsList.data.data.total;
          this.ArrrayList = newsList.data.data.records;
          this.loading = false;
          this.listTypes = false;
        }

        if (this.ArrrayList.length == 0) {
          this.loading = false;
          this.listTypes = true;
          this.total = 0;
          if (this.$route.query.isRegular == 2) {
            this.listTypes = false;
            this.total = this.Alltotal;
          }
        }
      }
    },
    // 部门
    async depList() {
      this.loading = true;
      const dep = await companyDepartmentSetting(this.queryParams);
      if (dep.data.code == 200) {
        if (dep.data.data.records && dep.data.data.records.length > 0) {
          this.total = dep.data.data.total;
          this.Alltotal = dep.data.data.total;
          this.depListS = dep.data.data.records;
          this.loading = false;
        } else {
          if (this.ArrrayList.length == 0) {
            this.listTypes = true;
          } else {
            this.listTypes = false;
          }
        }
      }
    },
    // 路由跳转
    async navRightFn(index, newsList, obj) {
      this.contents = index;
      this.$router.push({
        path: '/layout/companyProfile',
        query: {
          // id: obj.id,
          // columnPageType: obj.columnPageType,
          // isRegular: obj.isRegular,
          // paramID: obj.parentId,
          id: obj.id,
          columnPageType: obj.columnPageType,
          columnPageStyle: obj.columnPageStyle,
          isRegular: obj.isRegular,
          paramID: obj.parentId,
          isSubStyle: obj.isSubStyle,
          columnTheme: obj.columnTheme,
        },
      });

      this.queryParams.columnId = obj.id;
      this.$store.state.secondLevel = obj;
      this.$store.state.commonNavigationList = newsList;
      this.countent = index;
      this.$store.state.secondLevel.id = obj.id;
      // 修改状态
      this.$store.commit('changeState', 0);
      if (obj.isRegular == 2) {
        this.$store.dispatch('companyDepartmentSetting');
      } else {
        // 公司概况
        this.$store.dispatch('companyProfileList', obj.id);
        // 新闻列表
        this.$store.dispatch('newsListFn', this.queryParams);
      }

      // if (this.$route.query.isRegular == 2) {
      //   // 部门
      //   this.$router.push({
      //     path: '/layout/companyProfile/contentDetails',
      //     query: {
      //       id: obj.id,
      //       isRegular: 2,
      //     },
      //   });
      // } else {
      //   // 新闻
      //   this.$router.push({
      //     path: '/layout/companyProfile/contentDetails',
      //     query: {
      //       id: obj.id,
      //       columnId: obj.columnId,
      //       isRegular: 1,
      //     },
      //   });
      // }
      // this.nativeFn.columnId = obj.id;
      // this.$store.state.secondLevel = obj;
      // this.$store.state.commonNavigationList = newsList;
      // this.contents = index;
      // this.$store.state.secondLevel.id = obj.id;
      // // 修改状态
      // this.$store.commit('changeState', 0);
      // if (obj.isRegular == 2) {
      //   this.$store.dispatch('companyDepartmentSetting');
      // } else {
      //   // 公司概况
      //   this.$store.dispatch('companyProfileList', obj.id);
      //   // 新闻列表
      //   this.$store.dispatch('newsListFn', this.nativeFn);
      // }
    },
    getDetalis(item, index) {
      if (item.isExternalLink == 1) {
        window.open(item.externalLink, '_blank');
        return;
      }
      if (this.$route.query.isRegular == 2) {
        // 部门
        this.$router.push({
          path: '/layout/companyProfile/contentDetails',
          query: {
            id: item.id,
            isRegular: 2,
          },
        });
      } else {
        // 新闻
        this.$router.push({
          path: '/layout/companyProfile/contentDetails',
          query: {
            Carousel: this.id,
            id: item.id,
            columnId: this.id,
            isRegular: 1,
          },
        });
      }

      // 获取轮播图
      // if (!this.$store.state.secondLevel.columnsName) {
      //   this.paramID = this.$route.query.id;
      // } else {
      //   this.paramID = this.$route.query.paramID;
      // }
      // 轮播图
      // this.$store.dispatch('publicRotationChart', this.paramID);
      // if (this.$route.query.isRegular == '2') {
      //   this.$store.dispatch('companyDeails', item.id);
      // } else {
      //   // 新闻详情的值
      //   this.argument = {
      //     id: item.id,
      //     columnId: item.columnId,
      //   };
      //   // 文章详情
      //   this.$store.dispatch('companyArticleDetails', this.argument);
      // }
      // this.$router.push({
      //   path: '/layout/companyProfile/contentDetails',
      //   query: {
      //     id: item.id,
      //     currentIndex: index,
      //     isRegular: this.$route.query.isRegular,
      //   },
      // });
    },
    // 一页显示几条
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNum = 1;
      this.queryParams.columnId = this.id;
      if (this.$route.query.isRegular == 2) {
        this.depList();
      } else {
        this.getList(this.queryParams.columnId);
      }
    },
    handleCurrentChange(val) {
      this.queryParams.pageSize = 10;
      this.queryParams.pageNum = val;
      this.queryParams.columnId = this.id;
      if (this.$route.query.isRegular == 2) {
        this.depList();
      } else {
        this.getList(this.queryParams.columnId);
      }
    },
    desFn(index) {
      this.contents = index;
    },
  },
  computed: {
    ...mapState(['overallQueryParams']),
    ...mapState(['commColumnId']),
    ...mapState(['wholeTotal']),
    ...mapState(['departmentList']),
    // ...mapState(['newList']),
    ...mapState(['fullscreenLoading']),
    newsList() {
      return (this.parentList = this.$store.state.commonNavigationList);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.pagination_block {
  width: 100%;
  height: 3.125rem;
  margin-top: 0.625rem;
  text-align: right;
}
@import url('//at.alicdn.com/t/c/font_4147545_o4qeg60juf.css');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  .app-content {
    padding: 2.5rem 0;
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .leftNav {
      width: 18.75rem;
      background-color: #fff;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
      .lefthead,
      .leftheadchange {
        width: 100%;
        // height: 3.4375rem;
        background-color: #0aa4ee;
        font-size: 1.125rem;
        line-height: 3.4375rem;
        color: #fff;
        border-radius: 0.25rem 0.25rem 0 0;
        font-weight: 600;
      }
      .leftheadchange {
        background-color: #c50c11;
      }
      .parents {
        width: 100%;
        padding: 0 0.9375rem;
        .mutios {
          width: 100%;
          height: 2.1875rem;
          line-height: 2.1875rem;
          text-align: left;
          position: relative;
          padding: 0 0.625rem;
          margin-top: 0.625rem;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          color: black;
          cursor: pointer;
          .icsStyle {
            color: #c1c1c1;
          }
          .icon-xiayibu {
            color: #c1c1c1;
          }
          // &::before {
          //   content: '';
          //   width: 0.3125rem;
          //   height: 1.875rem;
          //   background-color: #0aa4ee;
          //   position: absolute;
          //   top: 0.0625rem;
          //   left: 0;
          // }
        }
        .classStyle,
        .classStylechange {
          width: 100%;
          height: 2.1875rem;
          line-height: 2.1875rem;
          text-align: left;
          position: relative;
          padding: 0 0.625rem;
          margin-top: 0.625rem;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          color: #0aa4ee;

          .icon-xiayibu {
            color: #0aa4ee;
          }
          &::before {
            content: '';
            width: 0.3125rem;
            height: 1.875rem;
            background-color: #0aa4ee;
            position: absolute;
            top: 0.0625rem;
            left: -0.9375rem;
          }
        }
        .classStylechange {
          color: #c50c11;
          .icon-xiayibu {
            color: #c50c11;
          }
          &::before {
            content: '';
            width: 0.3125rem;
            height: 1.875rem;
            background-color: #c50c11;
            position: absolute;
            top: 0.0625rem;
            left: -0.9375rem;
          }
        }
      }
    }
    .rightNav {
      flex: 1;
      width: 100%;
      .head_title {
        width: 100%;
        height: 3.4375rem;
        display: flex;
        align-items: center;
        border-bottom: 0.0625rem solid #0aa4ee;
        font-size: 0.875rem;
        padding: 0 0.625rem;
        color: #999999;
        img {
          margin-right: 0.3125rem;
        }
      }
      .headContent {
        width: 100%;
        border-radius: 0.25rem;
        margin-top: 0.625rem;
        background-color: #fff;
        padding: 0.625rem 0.9375rem;
        .statrLisr {
          width: 100%;
          height: 55px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 0.0625rem dashed rgb(186, 186, 186);
          text-align: left;
          padding: 0.4375rem 1.875rem;
          padding-right: 0;
          position: relative;
          /* &::before {
            content: '';
            width: 1.25rem;
            height: 1.25rem;
            background: url('../../../assets//allImage//presscenter//wenzitubiao@2x.png')
              no-repeat center / 100%;
            position: absolute;
            top: 0.625rem;
            left: 0;
          } */
          .leftBox {
            display: flex;
            width: 80%;
            align-items: baseline;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            .HOTNEWS {
              .firestormRe {
                display: inline-block;
                background-color: #ff9502;
                border-radius: 0.1875rem;
                margin-right: 0.3125rem;
                padding: 0.125rem 0.3125rem;
                color: #fff;
              }
              .firestorm {
                display: inline-block;
                background-color: #f277aa;
                border-radius: 0.1875rem;
                margin-right: 0.3125rem;
                padding: 0.125rem 0.3125rem;
                color: #fff;
              }
            }
            .depTitle {
              /* white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all; */
            }
          }
        }
      }
    }
  }
}
</style>
