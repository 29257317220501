var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[(
      _vm.$route.query.columnPageStyle == 'MASSIVE' &&
      _vm.$route.query.columnPageType == 'LIST' &&
      _vm.$route.query.isSubStyle == ''
    )?_c('List'):_vm._e(),(
      _vm.$route.query.columnPageStyle == 'MASSIVE' &&
      _vm.$route.query.columnPageType == 'LIST' &&
      _vm.$route.query.isSubStyle == 'TOP'
    )?_c('List'):_vm._e(),(
      _vm.$route.query.columnPageType == 'LIST' &&
      _vm.$route.query.columnPageStyle == 'TEXT' &&
      _vm.$route.query.isSubStyle == ''
    )?_c('headerList'):_vm._e(),(
      _vm.$route.query.columnPageType == 'LIST' &&
      _vm.$route.query.columnPageStyle == 'TEXT' &&
      _vm.$route.query.isSubStyle == 'TOP'
    )?_c('headerList'):_vm._e(),(
      _vm.$route.query.isSubStyle == 'LEFT' &&
      _vm.$route.query.columnPageStyle == 'TEXT' &&
      _vm.$route.query.columnPageType == 'LIST'
    )?_c('leftHandList'):_vm._e(),(
      _vm.$route.query.isSubStyle == 'LEFT' &&
      _vm.$route.query.columnPageType == 'LIST' &&
      _vm.$route.query.columnPageStyle == 'MASSIVE'
    )?_c('leftSideBlock'):_vm._e(),_c('keep-alive',[(_vm.isActive)?_c('router-view'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }