<template>
  <div class="box_app">
    <!-- <img
      class="picmsg"
      src="../../../assets//allImage//presscenter//toutu_xinwenzhognxin@1x.png"
      alt=""
    /> -->
    <div class="parentComponent" v-if="states == 0">
      <div class="app-content">
        <div class="leftNav">
          <div
            :class="
              $route.query.columnTheme == 'DEFAULT'
                ? 'lefthead'
                : 'leftheadchange'
            "
          >
            {{ $store.state.dynamicParentName.columnsName }}
          </div>

          <!-- 
          icsStyle:
                  $store.state.statusTypes == 0
                    ? $store.state.secondLevel.id === obj.id ||
                      $route.query.id == obj.id
                    : index === countent, -->

          <div class="parents">
            <div
              class="mutios"
              :class="{
                resultSlass:
                  $store.state.statusTypes == 0
                    ? $store.state.secondLevel.id === obj.id &&
                      $route.query.columnTheme == 'DEFAULT'
                    : index === countent,
                resultSlasschange:
                  $store.state.statusTypes == 0
                    ? $store.state.secondLevel.id === obj.id &&
                      $route.query.columnTheme == 'RED'
                    : index === countent,
              }"
              @click="navRightFn(index, newsList, obj)"
              v-for="(obj, index) in newsList"
              :key="obj.id"
            >
              <span> {{ obj.columnsName }}</span>
              <i class="iconfont icon-xiayibu"></i>
            </div>
          </div>
        </div>
        <div class="rightNav">
          <div class="head_title">
            <img
              width="1.25rem"
              height="1.25rem"
              src="../../../assets//allImage//presscenter/icon_home@2x.png"
              alt=""
            />
            <div>当前位置：</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{
                $store.state.dynamicParentName.columnsName
              }}</el-breadcrumb-item>
              <el-breadcrumb-item
                v-show="$store.state.secondLevel.columnsName"
                >{{
                  $store.state.statusTypes == 0
                    ? $store.state.secondLevel.columnsName
                    : currentNickname
                }}</el-breadcrumb-item
              >
            </el-breadcrumb>
          </div>
          <div
            class="headContent"
            v-loading="loading"
            element-loading-text="拼命加载中"
          >
            <div
              class="statrLisr"
              v-for="(item, index) in $route.query.isRegular == 2
                ? depListS
                : ArrrayList"
              :key="index"
            >
              <div class="picnsg">
                <img width="100%" :src="imgPic + item.picImp" alt="" />
              </div>
              <div class="tiemTitle">
                <div style="display: flex">
                  <img
                    style="
                      width: 1.25rem;
                      position: absolute;
                      top: 0;
                      left: -0.0625rem;
                    "
                    :src="
                      $route.query.columnTheme == 'DEFAULT'
                        ? defaultImg
                        : redImg
                    "
                    alt=""
                  />
                  <!-- 热 -->
                  <span class="HOTNEWS" v-if="item.articleMark == 'HOT'">
                    <span class="firestormRe">热</span>
                  </span>

                  <!-- 新 -->
                  <span class="HOTNEWS" v-if="item.articleMark == 'FLATNEW'">
                    <span class="firestorm">新</span>
                  </span>
                  <!-- 热新 -->
                  <span class="HOTNEWS" v-if="item.articleMark == 'HOTNEW'">
                    <span class="firestormRe">热</span>
                    <span class="firestorm">新</span>
                  </span>
                  <span style="color: #f26363" v-if="item.isTop == 1"
                    >[置顶]</span
                  >
                  <span class="depTitle">
                    {{
                      $route.query.isRegular == 2 ? item.deptName : item.title
                    }}</span
                  >
                </div>
                <div class="viwsdels">
                  <div>{{ item.updateTime }}</div>
                  <div
                    :class="
                      $route.query.columnTheme == 'DEFAULT'
                        ? 'delst'
                        : 'delstchange'
                    "
                    @click="getDetalis(item, index)"
                  >
                    查看详情>
                  </div>
                </div>
              </div>
            </div>
            <noDataPrompt v-if="listTypes" />
          </div>
        </div>
      </div>
      <div class="pagination_block" v-if="total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum"
          :page-sizes="[10, 20, 30]"
          :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 详情 -->
    <NewsDetails v-else></NewsDetails>
  </div>
</template>

<script>
import noDataPrompt from '../../noDataPrompt/index.vue';
import { mapState } from 'vuex';
import {
  slideshowList,
  newsListpAGE,
  companyDepartmentSetting,
} from '@/api/index';
import NewsDetails from '../NewsDetails/index.vue';
export default {
  components: {
    NewsDetails,
    noDataPrompt,
  },
  data() {
    return {
      depListS: [],
      Alltotal: 0,
      total: 0,
      loading: false,
      listTypes: false,
      imgPic: '',
      countent: 0,
      nativeFn: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      paramID: '',
      argument: {
        id: '',
        columnId: '',
      },
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      states: 0, //0 详情   1新闻
      currentPage4: 4,
      list: [
        {
          id: 0,
          title: '[置顶]广西高速公路全面推广差异化收费实施方案',
          time: '2023-01-01',
          pic: require('../../../assets/allImage/presscenter/tu1@2x.png'),
        },
        {
          id: 1,
          title: '公安部发布节日交通安全预警',
          time: '2023-02-02',
          pic: require('../../../assets/allImage/presscenter/tu2@2x.png'),
        },
        {
          id: 2,
          title: '我区消防救援车辆安装ETC实现全覆盖',
          time: '2023-03-03',
          pic: require('../../../assets/allImage/presscenter/tu3@2x.png'),
        },
        {
          id: 3,
          title: '关于票根发票服务平台暂停发票服务相关业务的通知',
          time: '2023-04-04',
          pic: require('../../../assets/allImage/presscenter/tu4@2x.png'),
        },
        {
          id: 4,
          title: '我区道路运输、高速公路部分路段实施交通管制',
          time: '2023-05-05',
          pic: require('../../../assets/allImage/presscenter/tu5@2x.png'),
        },
        {
          id: 5,
          title: '华夏银行率先发布广西ETC线上签约功能',
          time: '2023-06-06',
          pic: require('../../../assets/allImage/presscenter/tu6@2x.png'),
        },
        {
          id: 6,
          title: '关于开展短信协同投递服务的通告',
          time: '2023-07-07',
          pic: require('../../../assets/allImage/presscenter/tu7@2x.png'),
        },
        {
          id: 7,
          title: '7月1日起，高速公路通行费纸质发票将不能再抵税！',
          time: '2023-08-08',
          pic: require('../../../assets/allImage/presscenter/tu8@2x.png'),
        },
        {
          id: 8,
          title:
            '广西农村信用社与广西捷通公司举行八桂行ETC后付费记账卡首发仪式',
          time: '2023-09-09',
          pic: require('../../../assets/allImage/presscenter/tu9@2x.png'),
        },
        {
          id: 9,
          title: '交通部推动取消高速省界收费站 用ETC等替人工收费',
          time: '2023-10-10',
          pic: require('../../../assets/allImage/presscenter/tu10@2x.png'),
        },
      ],
      currentPosition: [
        {
          id: 0,
          title: '首页 >',
        },
        {
          id: 1,
          title: '新闻中心 >',
        },
        {
          id: 2,
          title: '行业新闻 ',
        },
      ],
      contents: 0,
      navlist: [
        {
          id: 0,
          title: '行业新闻',
        },
        {
          id: 1,
          title: '媒体报道',
        },
        {
          id: 2,
          title: '公司新闻',
        },
      ],
      defaultImg: require('../../../assets//allImage//presscenter//wenzitubiao@2x.png'),
      redImg: require('../../../assets/allImage/featureColumn/tubiao@2x.png'),
    };
  },
  created() {
    this.depList();
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newId, oldId) {
        this.id = newId;
        this.getList(newId); // 文章列表
      },
    },
  },
  methods: {
    async getList(id) {
      this.ArrrayList = [];
      this.loading = true;
      this.queryParams.columnId = id;
      const newsList = await newsListpAGE(this.queryParams);
      if (newsList.data.code) {
        if (
          newsList.data.data.records &&
          newsList.data.data.records.length > 0
        ) {
          this.total = newsList.data.data.total;
          this.ArrrayList = newsList.data.data.records;
          this.loading = false;
          this.listTypes = false;
        }

        if (this.ArrrayList.length == 0) {
          this.loading = false;
          this.listTypes = true;
          this.total = 0;
          if (this.$route.query.isRegular == 2) {
            this.listTypes = false;
            this.total = this.Alltotal;
          }
        }
      }
    },
    async depList() {
      this.loading = true;
      const dep = await companyDepartmentSetting(this.queryParams);
      if (dep.data.code == 200) {
        if (dep.data.data.records && dep.data.data.records.length > 0) {
          this.total = dep.data.data.total;
          this.Alltotal = dep.data.data.total;
          this.depListS = dep.data.data.records;
          this.loading = false;
        } else {
          if (this.ArrrayList.length == 0) {
            this.listTypes = true;
          } else {
            this.listTypes = false;
          }
        }
      }
    },
    async navRightFn(index, newsList, obj) {
      this.countent = index;
      this.queryParams.columnId = obj.id;
      this.$store.state.secondLevel = obj;
      this.$store.state.commonNavigationList = newsList;
      // this.countent = index;
      this.$store.state.secondLevel.id = obj.id;
      // 修改状态
      this.$store.commit('changeState', 0);
      if (obj.isRegular == 2) {
        this.$store.dispatch('companyDepartmentSetting');
      } else {
        // 公司概况
        this.$store.dispatch('companyProfileList', obj.id);
        // 新闻列表
        this.$store.dispatch('newsListFn', this.queryParams);
      }

      this.$router.push({
        path: '/layout/companyProfile',
        query: {
          // id: obj.id,
          // columnPageType: obj.columnPageType,
          // isRegular: obj.isRegular,
          // paramID: obj.parentId,
          id: obj.id,
          columnPageType: obj.columnPageType,
          columnPageStyle: obj.columnPageStyle,
          isRegular: obj.isRegular,
          isSubStyle: obj.isSubStyle,
          paramID: obj.parentId,
          columnTheme: obj.columnTheme,
        },
      });

      localStorage.setItem('comIndex', JSON.stringify(this.countent));
    },
    getDetalis(item, index) {
      // 获取轮播图
      if (!this.$store.state.secondLevel.columnsName) {
        this.paramID = this.$route.query.id;
      } else {
        this.paramID = this.$route.query.paramID;
      }
      // 轮播图
      this.$store.dispatch('publicRotationChart', this.paramID);
      if (this.$route.query.isRegular == '2') {
        this.$store.dispatch('companyDeails', item.id);
      } else {
        // 新闻详情的值
        this.argument = {
          id: item.id,
          columnId: item.columnId,
        };
        // 文章详情
        this.$store.dispatch('companyArticleDetails', this.argument);
      }

      this.$router.push({
        path: '/layout/companyProfile/contentDetails',
        query: {
          Carousel: this.id,
          id: item.id,
          columnId: this.id,
          isRegular: 1,
        },
      });

      // this.$router.push({
      //   path: '/layout/companyProfile/contentDetails',
      //   query: {
      //     Carousel: this.id,
      //     id: item.id,
      //     currentIndex: index,
      //     isRegular: this.$route.query.isRegular,
      //   },
      // });
    },
    desFn(index) {
      this.contents = index;
    },
    // 一页显示几条
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNum = 1;
      this.queryParams.columnId = this.id;
      if (this.$route.query.isRegular == 2) {
        this.depList();
      } else {
        this.getList(this.queryParams.columnId);
      }
    },
    handleCurrentChange(val) {
      this.queryParams.pageSize = 10;
      this.queryParams.pageNum = val;
      this.queryParams.columnId = this.id;
      if (this.$route.query.isRegular == 2) {
        this.depList();
      } else {
        this.getList(this.queryParams.columnId);
      }
    },
    // 修改状态
    viewDetailsFn() {
      this.states = 1;
    },
  },
  computed: {
    ...mapState(['overallQueryParams']),
    ...mapState(['commColumnId']),
    ...mapState(['wholeTotal']),
    ...mapState(['departmentList']),
    ...mapState(['newList']),
    ...mapState(['fullscreenLoading']),
    newsList() {
      return (this.parentList = this.$store.state.commonNavigationList);
    },
  },
  mounted() {
    this.imgPic = process.env.VUE_APP_BASE_URL;
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.pagination_block {
  width: 80%;
  height: 3.125rem;
  margin-top: 0.625rem;
  text-align: right;
}
@import url('//at.alicdn.com/t/c/font_4147545_o4qeg60juf.css');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.box_app {
  width: 100%;
  margin-top: 20px;
  .picmsg {
    width: 100%;
    margin-bottom: 0.9375rem;
  }
  background-color: #f5f5f5;
  .app-content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .leftNav {
      width: 18.75rem;
      background-color: #fff;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
      padding: 1.875rem 0;
      padding-top: 0;
      .lefthead,
      .leftheadchange {
        width: 100%;
        height: 3.4375rem;
        background-color: #0aa4ee;
        font-size: 1.125rem;
        line-height: 3.4375rem;
        color: #fff;
        border-radius: 0.25rem 0.25rem 0 0;
        font-weight: 600;
      }
      .leftheadchange {
        background-color: #c50c11;
      }
      .parents {
        width: 100%;
        padding: 0 0.9375rem;
        .mutios {
          width: 100%;
          height: 2.1875rem;
          line-height: 2.1875rem;
          /* border-bottom: 0.0625rem solid #b4aeae; */
          text-align: left;
          position: relative;
          padding: 0 0.625rem;
          margin-top: 0.625rem;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          color: black;
          .icon-xiayibu {
            color: #c1c1c1;
          }
        }
        .resultSlass,
        .resultSlasschange {
          width: 100%;
          height: 2.1875rem;
          line-height: 2.1875rem;
          /* border-bottom: 0.0625rem solid #b4aeae; */
          text-align: left;
          position: relative;
          padding: 0 0.625rem;
          margin-top: 0.625rem;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          color: #0aa4ee;
          .icon-xiayibu {
            color: #c1c1c1;
          }
          .icon-xiayibu {
            color: #0aa4ee;
          }
          &::before {
            content: '';
            width: 0.3125rem;
            height: 1.875rem;
            background-color: #0aa4ee;
            position: absolute;
            top: 0.0625rem;
            left: -15px;
          }
        }
        .resultSlasschange {
          color: #c50c11;
          .icon-xiayibu {
            color: #c50c11;
          }
          &::before {
            content: '';
            width: 0.3125rem;
            height: 1.875rem;
            background-color: #c50c11;
            position: absolute;
            top: 0.0625rem;
            left: -15px;
          }
        }
        .classStyle {
          width: 100%;
          height: 2.1875rem;
          line-height: 2.1875rem;
          border-bottom: 0.0625rem dashed black;
          text-align: left;
          position: relative;
          padding: 0 0.625rem;
          margin-top: 0.625rem;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          color: #0aa4ee;

          .icon-xiayibu {
            color: #0aa4ee;
          }
          &::before {
            content: '';
            width: 0.3125rem;
            height: 1.875rem;
            background-color: #0aa4ee;
            position: absolute;
            top: 0.0625rem;
            left: -0.9375rem;
          }
        }
      }
    }
    .rightNav {
      flex: 1;

      .head_title {
        width: 100%;
        height: 3.4375rem;
        display: flex;
        align-items: center;
        border-bottom: 0.0625rem solid #0aa4ee;
        font-size: 0.75rem;
        padding: 0 0.625rem;
        color: #c1c1c1;

        img {
          margin-right: 0.3125rem;
        }
      }
      .headContent {
        width: 100%;
        border-radius: 0.25rem;
        margin-top: 0.625rem;
        .statrLisr {
          width: 100%;
          height: 10.125rem;
          text-align: left;
          padding: 0 1.875rem;
          padding-right: 0;
          background-color: #fff;
          display: flex;
          align-items: center;
          margin-top: 0.625rem;
          border-radius: 0.25rem;
          justify-content: space-between;
          position: relative;
          .picnsg {
            width: 5rem;
            height: 5rem;
            margin-right: 0.625rem;
            border-radius: 0.25rem;
            img {
              width: 5rem;
              height: 5rem;
              border-radius: 4px;
            }
          }
          .tiemTitle {
            flex: 1;
            padding: 0 20px;
            .depTitle {
              /* height: 2.5rem; */
              /* // width: 31.25rem; */
              text-align: left;
              text-overflow: ellipsis;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              cursor: pointer;
              /* padding-bottom: 2.9375rem; */
            }
            .HOTNEWS {
              .firestormRe {
                display: inline-block;
                background-color: #ff9502;
                border-radius: 0.1875rem;
                margin-right: 0.3125rem;
                padding: 0.125rem 0.3125rem;
                color: #fff;
              }
              .firestorm {
                display: inline-block;
                background-color: #f277aa;
                border-radius: 0.1875rem;
                margin-right: 0.3125rem;
                padding: 0.125rem 0.3125rem;
                color: #fff;
              }
            }
            .viwsdels {
              width: 100%;
              display: flex;
              margin-top: 1.25rem;
              justify-content: space-between;
              border-top: 0.0625rem dashed rgb(122, 116, 116);
              padding-top: 10px;
              cursor: pointer;
              .delst,
              .delstchange {
                margin-right: 0.625rem;
                color: #0aa4ee;
              }
              .delstchange {
                color: #c50c11;
              }
            }
          }
          /* &::before {
            content: '';
            width: 1.25rem;
            height: 1.25rem;
            background: url('../../../assets//allImage//presscenter//wenzitubiao@2x.png')
              no-repeat center / 100%;
            position: absolute;
            top: 0;
            left: 0;
          } */
        }
      }
    }
  }
}
</style>
