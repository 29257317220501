<template>
  <div class="app">
    <!-- 部门块状 -->
    <!-- <List v-if="$route.query.isRegular == 2"></List> -->
    <!-- 列表块状 -->
    <List
      v-if="
        $route.query.columnPageStyle == 'MASSIVE' &&
        $route.query.columnPageType == 'LIST' &&
        $route.query.isSubStyle == ''
      "
    ></List>

    <List
      v-if="
        $route.query.columnPageStyle == 'MASSIVE' &&
        $route.query.columnPageType == 'LIST' &&
        $route.query.isSubStyle == 'TOP'
      "
    ></List>
    <!-- <List
      v-if="
        $route.query.columnPageStyle == 'MASSIVE' &&
        $route.query.columnPageType == 'LIST'
      "
    ></List> -->
    <!-- 列表 -->
    <headerList
      v-if="
        $route.query.columnPageType == 'LIST' &&
        $route.query.columnPageStyle == 'TEXT' &&
        $route.query.isSubStyle == ''
      "
    >
    </headerList>

    <headerList
      v-if="
        $route.query.columnPageType == 'LIST' &&
        $route.query.columnPageStyle == 'TEXT' &&
        $route.query.isSubStyle == 'TOP'
      "
    >
    </headerList>

    <!-- 左侧文字列表 -->
    <leftHandList
      v-if="
        $route.query.isSubStyle == 'LEFT' &&
        $route.query.columnPageStyle == 'TEXT' &&
        $route.query.columnPageType == 'LIST'
      "
    ></leftHandList>
    <!-- 左侧块状 -->
    <leftSideBlock
      v-if="
        $route.query.isSubStyle == 'LEFT' &&
        $route.query.columnPageType == 'LIST' &&
        $route.query.columnPageStyle == 'MASSIVE'
      "
    ></leftSideBlock>

    <keep-alive>
      <router-view v-if="isActive"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import headerList from '../headerList';
import leftHandList from '../leftHandList';
import leftSideBlock from '../leftSideBlock';
import List from '../list';

export default {
  components: {
    headerList,
    leftHandList,
    leftSideBlock,
    List,
  },
  data() {
    return {
      isActive: true, // 控制组件的激活状态
      defalutList: [],
      id: 0,
      imgUrl: '',
    };
  },
  computed: {
    // ...mapState['carouselList'],
    ...mapState(['carouselList']),
  },
  activated() {
    // 组件被激活时触发，可以在此处重新创建组件或执行初始化逻辑
    // 例如，重新获取数据、重置状态等
    this.isActive = true;
  },
  deactivated() {
    // 组件被停用时触发，可以在此处进行组件的销毁或清理逻辑
    // 例如，取消订阅、清空数据等
    this.isActive = false;
  },
  mounted() {
    this.imgUrl = process.env.VUE_APP_BASE_URL;
    this.$watch('$route.query.id', (newId) => {
      this.id = newId;
    });
  },
};
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  .app-content {
    width: 100%;
    .msgPic {
      width: 100%;
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
