<template>
  <div class="app">
    <!-- 列表页 -->
    <div class="app-content">
      <div
        class="item_son"
        v-loading="loading"
        element-loading-text="拼命加载中"
      >
        <div
          class="obj_box"
          v-for="(item, index) in $route.query.isRegular == 2
            ? depListS
            : ArrrayList"
          :key="item.id"
        >
          <img
            style="width: 1.25rem; position: absolute; top: 0; left: -0.0625rem"
            :src="$route.query.columnTheme == 'DEFAULT' ? defaultImg : redImg"
            alt=""
          />
          <div class="my_pic" v-if="$route.query.isRegular !== '2'">
            <img :src="imgPic + item.picImp" alt="" />
          </div>
          <div class="tow_Laout">
            <div class="title_sk" @click="getDetalis(item, index)">
              <!-- 热 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'HOT'">
                <span class="firestormRe">热</span>
              </span>

              <!-- 新 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'FLATNEW'">
                <span class="firestorm">新</span>
              </span>
              <!-- 热新 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'HOTNEW'">
                <span class="firestormRe">热</span>
                <span class="firestorm">新</span>
              </span>
              <span style="color: #f26363" v-if="item.isTop == 1">[置顶]</span>
              <span class="depNameAndTitle">
                {{ $route.query.isRegular == 2 ? item.deptName : item.title }}
              </span>
            </div>
            <div class="content_sk">
              <div>{{ item.updateTime }}</div>
              <div
                :class="
                  $route.query.columnTheme == 'DEFAULT'
                    ? 'vd_ds'
                    : 'vd_dschange'
                "
                @click="getDetalis(item, index)"
              >
                查看详情 >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 提示无数据 -->
      <noDataPrompt v-if="listTypes" />
    </div>
    <!-- 分页 -->
    <!-- $route.query.columnPageType == 'LIST'-->
    <div class="pagination_block" v-if="total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  slideshowList,
  newsListpAGE,
  companyDepartmentSetting,
} from '@/api/index';
import noDataPrompt from '../../noDataPrompt/index.vue';

export default {
  components: {
    noDataPrompt,
  },

  data() {
    return {
      defaultImg: require('../../../assets//allImage//presscenter//wenzitubiao@2x.png'),
      redImg: require('../../../assets/allImage/featureColumn/tubiao@2x.png'),
      Alltotal: 0,
      depListS: [], // 不能猛块状
      loading: false,
      listTypes: false,
      ArrrayList: [],
      // fullscreenLoading: false, //加载效果
      pageStatus: '',
      total: 0,
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      imgPic: '',
      columnId: '',
      id: 0,
      NewList: [],
      countent: 0,
      currentPage4: 4,
      imageSize: 'cover',
      paramID: '',
      argument: {
        id: '',
        columnId: '',
      },
    };
  },

  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newId, oldId) {
        this.id = newId;
        this.getList(newId); // 文章列表
      },
    },
  },
  created() {
    this.depList();
  },
  methods: {
    async getList(id) {
      this.ArrrayList = [];
      this.loading = true;
      this.queryParams.columnId = id;
      const newsList = await newsListpAGE(this.queryParams);
      if (newsList.data.code) {
        if (
          newsList.data.data.records &&
          newsList.data.data.records.length > 0
        ) {
          this.total = newsList.data.data.total;
          this.ArrrayList = newsList.data.data.records;
          this.loading = false;
          this.listTypes = false;
        }
        if (this.ArrrayList.length == 0) {
          this.loading = false;
          this.listTypes = true;
          this.total = 0;
          if (this.$route.query.isRegular == 2) {
            this.listTypes = false;
            this.total = this.Alltotal;
          }
        }
      }
    },
    // 部门
    async depList() {
      this.depListS = [];
      this.loading = true;
      const dep = await companyDepartmentSetting(this.queryParams);
      if (dep.data.code == 200) {
        if (dep.data.data.records && dep.data.data.records.length > 0) {
          this.total = dep.data.data.total;
          this.Alltotal = dep.data.data.total;
          this.depListS = dep.data.data.records;
          this.loading = false;
        } else {
          if (this.ArrrayList.length == 0) {
            this.loading = false;
            this.listTypes = true;
            this.total = 0;
            if (this.$route.query.isRegular !== 2) {
              this.listTypes = false;
            }
          }
        }
      }
    },
    getDetalis(item, index) {
      if (item.isExternalLink == 1) {
        window.open(item.externalLink, '_blank');
        return;
      }
      if (this.$route.query.isRegular == 2) {
        // 部门
        this.$router.push({
          path: '/layout/companyProfile/contentDetails',
          query: {
            id: item.id,
            isRegular: 2,
          },
        });
      } else {
        // 新闻
        this.$router.push({
          path: '/layout/companyProfile/contentDetails',
          query: {
            Carousel: this.id,
            id: item.id,
            columnId: this.id,
            isRegular: 1,
          },
        });
      }
    },
    // navFn(index) {
    //   this.countent = index;
    // },
    // 一页显示几条
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNum = 1;
      this.queryParams.columnId = this.id;
      if (this.$route.query.isRegular == 2) {
        this.depList();
      } else {
        this.getList(this.queryParams.columnId);
      }
    },
    handleCurrentChange(val) {
      this.queryParams.pageSize = 10;
      this.queryParams.pageNum = val;
      this.queryParams.columnId = this.id;
      if (this.$route.query.isRegular == 2) {
        this.depList();
      } else {
        this.getList(this.queryParams.columnId);
      }
    },
  },
  computed: {
    ...mapState(['overallQueryParams']),
    ...mapState(['commColumnId']),
    // ...mapState(['wholeTotal']),
    ...mapState(['departmentList']),
    ...mapState(['newList']),
    ...mapState(['fullscreenLoading']),
  },
  mounted() {
    this.pageStatus = this.$store.state.pageIdentity;
    this.imgPic = process.env.VUE_APP_BASE_URL;
  },
  // watch: {
  //   $route(to) {
  //     this.id = to.query.id;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  background-color: #f5f5f5;
  .subnavigation {
    width: 80%;
    height: 2.1875rem;
    margin: 0 auto;
    padding: 0 5rem;
    display: flex;
    align-items: center;
    .moduleNavigation {
      padding: 0.3125rem 0.625rem;
      border: 0.0625rem #c50c11 solid;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
      color: #c50c11;
      margin-top: 0.625rem;
      cursor: pointer;
    }
    .navst {
      padding: 0.3125rem 0.625rem;
      background-color: #c50c11;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
      color: #fff;
      margin-top: 0.625rem;
      cursor: pointer;
    }
  }
  .app-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .item_son {
      width: 80%;
      padding: 0 5rem;
      display: flex;
      flex-wrap: wrap;
      margin: 1.875rem;
      .obj_box {
        width: 37.5rem;
        height: 7.5rem;
        border-radius: 0.5rem;
        padding: 1.25rem 1.25rem 0 1.25rem;
        margin: 0.625rem;
        background-color: #fff;
        display: flex;
        // justify-content: space-between;
        position: relative;
        .my_pic {
          width: 5rem;
          height: 5rem;
          margin-right: 0.9375rem;
          margin-left: 0.625rem;
          img {
            width: 100%;
            height: 100%;
            border-radius: 0.25rem;
          }
        }
        .tow_Laout {
          flex: 1;
          .title_sk {
            height: 2.5rem;
            // width: 31.25rem;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            cursor: pointer;
            padding-bottom: 2.9375rem;
          }
          .HOTNEWS {
            .firestormRe {
              display: inline-block;
              background-color: #ff9502;
              border-radius: 0.1875rem;
              margin-right: 0.3125rem;
              padding: 0.125rem 0.3125rem;
              color: #fff;
            }
            .firestorm {
              display: inline-block;
              background-color: #f277aa;
              border-radius: 0.1875rem;
              margin-right: 0.3125rem;
              padding: 0.125rem 0.3125rem;
              color: #fff;
            }
          }

          .content_sk {
            height: 2.1875rem;
            margin-top: 0.625rem;
            font-size: 0.75rem;
            line-height: 2.1875rem;
            border-top: 0.0625rem dashed #b9b7b7;
            display: flex;
            justify-content: space-between;
            .vd_ds,
            .vd_dschange {
              color: #0aa4ee;
              cursor: pointer;
            }
            .vd_dschange {
              color: #c50c11;
            }
          }
        }
        .srcImg {
          width: 1.25rem;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .pagination_block {
    width: 80%;
    height: 3.125rem;
    margin: 0 auto;
    text-align: right;
    padding-right: 5rem;
  }
}
</style>
