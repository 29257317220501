<template>
  <div class="app">
    <div class="app-content">
      <div
        class="item_son"
        v-loading="loading"
        element-loading-text="拼命加载中"
      >
        <div
          class="obj_box"
          v-for="(item, index) in $route.query.isRegular == 2
            ? depListS
            : ArrrayList"
          :key="index"
          @click="getDetalis(item, index)"
        >
          <img
            style="width: 1.25rem; position: absolute; top: 0; left: -0.0625rem"
            :src="$route.query.columnTheme == 'DEFAULT' ? defaultImg : redImg"
            alt=""
          />

          <div class="tow_Laout">
            <div class="title_sk">
              <!-- 热 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'HOT'">
                <span class="firestormRe">热</span>
              </span>

              <!-- 新 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'FLATNEW'">
                <span class="firestorm">新</span>
              </span>
              <!-- 热新 -->
              <span class="HOTNEWS" v-if="item.articleMark == 'HOTNEW'">
                <span class="firestormRe">热</span>
                <span class="firestorm">新</span>
              </span>
              <span style="color: #f26363" v-if="item.isTop == 1">[置顶]</span>
              <span style="">{{
                $route.query.isRegular == 2 ? item.deptName : item.title
              }}</span>
            </div>
            <div class="content_sk">
              <div>{{ item.updateTime }}</div>
              <!-- <div class="vd_ds">查看详情></div> -->
            </div>
          </div>
        </div>
        <!-- 新闻列表 -->
        <noDataPrompt v-if="listTypes" />
      </div>
    </div>

    <!-- 分页 -->
    <div class="pagination_block" v-if="total > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import noDataPrompt from '../../noDataPrompt';
import {
  slideshowList,
  newsListpAGE,
  companyDepartmentSetting,
} from '@/api/index';
import { mapState } from 'vuex';

export default {
  components: {
    noDataPrompt,
  },
  data() {
    return {
      defaultImg: require('../../../assets//allImage//presscenter//wenzitubiao@2x.png'),
      redImg: require('../../../assets/allImage/featureColumn/tubiao@2x.png'),
      Alltotal: 0,
      total: 0,
      loading: false,
      listTypes: false, // 列表状态
      ArrrayList: [], // 文章列表
      depListS: [], //部门列表
      myId: '',
      paramID: '',
      countent: 0,
      argument: {
        id: '',
        columnId: '',
      },
      navList: [
        {
          id: 0,
          label: '上级精神',
        },
        {
          id: 1,
          label: '捷通行动',
        },
        {
          id: 2,
          label: '学习成果',
        },
      ],
      currentPage4: 4,
      imageSize: 'cover',
      list: [],
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
    };
  },

  computed: {
    ...mapState(['overallQueryParams']),
    ...mapState(['commColumnId']),
    ...mapState(['wholeTotal']),
    ...mapState(['departmentList']),
    ...mapState(['newList']),
    ...mapState(['fullscreenLoading']),
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newId, oldId) {
        this.id = newId.id;
        this.getList(newId.id); // 文章列表
      },
    },
  },
  created() {
    if (this.$route.query.isRegular == 2) {
      this.depList();
    } else {
      this.getList(this.queryParams.columnId);
    }
  },
  methods: {
    async getList(id) {
      this.ArrrayList = [];
      this.loading = true;
      this.queryParams.columnId = id;
      const newsList = await newsListpAGE(this.queryParams);
      if (newsList.data.code) {
        if (
          newsList.data.data.records &&
          newsList.data.data.records.length > 0
        ) {
          this.total = newsList.data.data.total;
          this.ArrrayList = newsList.data.data.records;
          this.loading = false;
          this.listTypes = false;
        }
        if (this.ArrrayList.length == 0) {
          this.loading = false;
          this.listTypes = true;
          this.total = 0;
          if (this.$route.query.isRegular == 2) {
            this.listTypes = false;
            this.total = this.Alltotal;
          }
        }
      }
    },
    async depList() {
      this.depListS = [];
      const dep = await companyDepartmentSetting(this.queryParams);
      if (dep.data.code == 200) {
        if (dep.data.data.records && dep.data.data.records.length > 0) {
          this.total = dep.data.data.total;
          this.Alltotal = dep.data.data.total;
          this.depListS = dep.data.data.records;
          this.loading = false;
          this.listTypes = false; // 关闭新闻列表暂无数据提示
        }
        if (this.depListS.length == 0) {
          this.loading = false;
          this.listTypes = true;
          this.total = 0;
          if (this.$route.query.isRegular == 2) {
            this.listTypes = false;
            this.total = this.Alltotal;
          }
        }
      }
    },
    getDetalis(item, index) {
      if (item.isExternalLink == 1) {
        window.open(item.externalLink, '_blank');
        return;
      }
      // 获取轮播图
      // if (!this.$store.state.secondLevel.columnsName) {
      //   this.paramID = this.$route.query.id;
      // } else {
      //   this.paramID = this.$route.query.paramID;
      // }
      // this.$store.dispatch('publicRotationChart', this.paramID);
      if (this.$route.query.isRegular == 2) {
        // 部门
        this.$router.push({
          path: '/layout/companyProfile/contentDetails',
          query: {
            Carousel: this.id,
            id: item.id,
            isRegular: 2,
          },
        });
      } else {
        // 新闻
        this.$router.push({
          path: '/layout/companyProfile/contentDetails',
          query: {
            Carousel: this.id,
            id: item.id,
            columnId: this.id,
            isRegular: 1,
          },
        });
      }

      // if (this.$route.query.isRegular == '2') {
      //   this.$store.dispatch('companyDeails', item.id);
      // } else {
      //   // 新闻详情的值
      //   this.argument = {
      //     id: item.id,
      //     columnId: item.columnId,
      //   };
      //   // 文章详情
      //   this.$store.dispatch('companyArticleDetails', this.argument);
      // }
    },
    navFn(index) {
      this.countent = index;
    },
    // 一页显示几条
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.queryParams.pageNum = 1;
      this.queryParams.columnId = this.id;
      if (this.$route.query.isRegular == 2) {
        this.depList();
      } else {
        this.getList(this.queryParams.columnId);
      }
    },
    handleCurrentChange(val) {
      this.queryParams.pageSize = 10;
      this.queryParams.pageNum = val;
      this.queryParams.columnId = this.id;
      if (this.$route.query.isRegular == 2) {
        this.depList();
      } else {
        this.getList(this.queryParams.columnId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  // background-color: #f5f5f5;
  .subnavigation {
    width: 80%;
    height: 2.1875rem;
    margin: 0 auto;
    padding: 0 5rem;
    display: flex;
    align-items: center;
    .moduleNavigation {
      padding: 0.3125rem 0.625rem;
      border: 0.0625rem #c50c11 solid;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
      color: #c50c11;
      margin-top: 0.625rem;
      cursor: pointer;
    }
    .navst {
      padding: 0.3125rem 0.625rem;
      background-color: #c50c11;
      border-radius: 0.25rem;
      margin-right: 0.9375rem;
      color: #fff;
      margin-top: 0.625rem;
      cursor: pointer;
    }
  }
  .app-content {
    width: 100%;
    display: flex;
    justify-content: center;

    .item_son {
      width: 80%;
      padding: 3rem;
      background-color: #fff;
      margin-bottom: 0.9375rem;
      border-radius: 0.5rem;
      margin-top: 1.25rem;
      .obj_box {
        cursor: pointer;
        width: 100%;
        height: 2.1875rem;
        padding-left: 2.1875rem;
        margin-top: 25px;
        position: relative;
        border-bottom: 0.0625rem dashed black;
        .tow_Laout {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title_sk {
            text-align: left;
            display: flex;
            align-items: baseline;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            width: 85%;
            .HOTNEWS {
              .firestormRe {
                display: inline-block;
                background-color: #ff9502;
                border-radius: 0.1875rem;
                margin-right: 0.3125rem;
                padding: 0.125rem 0.3125rem;
                color: #fff;
              }
              .firestorm {
                display: inline-block;
                background-color: #f277aa;
                border-radius: 0.1875rem;
                margin-right: 0.3125rem;
                padding: 0.125rem 0.3125rem;
                color: #fff;
              }
            }
          }

          .content_sk {
            .vd_ds {
              color: #0aa4ee;
            }
          }
        }
        .srcImg {
          width: 1.25rem;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .pagination_block {
    width: 80%;
    height: 3.125rem;
    margin: 0 auto;
    text-align: right;
    padding-right: 5rem;
  }
}
</style>
